@use "global" as *;

.fv-swiper {
    position: relative;
}

.fv-swiper .swiper {
    padding-bottom: prem(30);

    @include mq(md) {
        padding-bottom: 0;
    }
}

.fv-swiper__main .swiper-slide {
    transform: scale(.822);
}

.fv-swiper__main .swiper-slide-active {
    transform: scale(1);

    @include mq(md) {
        width: 100%;
    }
}

.fv-swiper__main .swiper-wrapper {
    transition-timing-function: linear;
}

.fv-swiper__main .swiper-slide {
    max-width: prem(863);
}

.fv-swiper__main .swiper-slide a {
    transition: $delay all ease;
    display: inline-block;
}

.fv-swiper__main .swiper-slide a:hover img {
    border: 4px solid #fff;
}

.fv-swiper__main .swiper-slide img,
.fv-swiper__thumbnail .swiper-slide img {
    width: 100%;
    aspect-ratio: 863/486;
    max-width: prem(863);
    object-fit: cover;
    border-radius: prem(20);
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));

    @include mq(md) {
        border-radius: prem(10);
    }
}

.fv-swiper .swiper-pagination {
    bottom: prem(65);
    margin-top: prem(-10);

    @include mq(md) {
        bottom: prem(120);
    }
}

.fv-swiper .swiper-pagination .swiper-pagination-bullet {
    background: $accent;
}

.fv-swiper .swiper-button-next {
    width: prem(50);
    height: prem(50);
    background-color: $accent;
    border-radius: 50%;
    border: 2px solid #fff;
    right: prem(100);
}

.fv-swiper .swiper-button-prev {
    width: prem(50);
    height: prem(50);
    background-color: $accent;
    border-radius: 50%;
    border: 2px solid #fff;
    left: prem(100);
}

.fv-swiper .swiper-button-next:hover,
.fv-swiper .swiper-button-prev:hover {
    background-color: #fff;
    border: 2px solid $accent;
}

.fv-swiper .swiper-button-next:hover::after,
.fv-swiper .swiper-button-prev:hover::after {
    background-color: $accent;
}


.fv-swiper .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: "";
}

.fv-swiper .swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: "";
}

.swiper-pagination-bullets {
    position: unset;
    top: unset;
    bottom: 0px;
    left: 0;
    right: 0;
}

.swiper-pagination-bullet {
    margin: 0 5px;
    width: prem(14);
    height: prem(14);
}


.fv-swiper__thumbnail {
    padding: prem(10);
}

.fv-swiper__thumbnail .swiper-slide {
    cursor: pointer;

}

.fv-swiper__thumbnail .swiper-slide img {
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.fv-swiper__thumbnail .swiper-slide.swiper-slide-thumb-active img {
    border: 2px solid #fff;
    box-sizing: border-box;
}