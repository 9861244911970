@use "global" as *;

.sub-company{
    margin-top: prem(108);
    @include mq(md){
        margin-top: prem(40);
    }
}

.sub-company__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    @include mq(md){
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}

.sub-company__title {
    padding-left: prem(20);
}

.sub-company__content-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: prem(800);
    width: 100%;
    @include mq(md){
        max-width: 100%;
        margin-left: 0;
    }
}

.sub-company__profile {
    margin-top: prem(40);
    @include mq(md){
        margin-top: prem(20);
    }
}