@use "global" as *;

.sub-page-title {
    font-size: prem(28);
    line-height: calc(42 /28);
    font-weight: bold;
    @include mq(md){
        font-size: prem(24);
        line-height: calc(36 /24);
    }
}