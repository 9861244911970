@use "global" as *;

.campaign {
    margin-top: prem(30);
    overflow-x: hidden;
    @include mq(md){
        margin-top: prem(40);
    }
}

.campaign__title {
    @include mq(md){
        padding: 0 prem(20);
    }
} 

.campaign__text {
    font-size: prem(16);
    line-height: calc(28 / 16);
    text-align: center;
    margin-top: prem(20);
    @include mq(md){
        text-align: left;
        padding: 0 prem(20);
    }
}

.campaign__inner {
    padding: 0 prem(80);
    width: 100%;
    margin-right: calc(50% - 50vw);

    @include mq(md){
        padding: 0;
    }
}

.campaign__swiper {
    overflow: visible;
    max-width: 1160px;
    padding: 0 prem(20);
    margin: 0 auto;
    @include mq(md){
        max-width: initial;
        padding: initial;
        margin: initial;
    }
}