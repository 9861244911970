// どっちファーストの設定（"sp" or "pc"）
$startFrom: pc;

// デフォルト値を設定
$mediaquerys: (
  sm: "screen",
  md: "screen",
  lg: "screen",
  xl: "screen",
);

@if $startFrom == sp {
  //スマホファースト
  $breakpoints: (
    sm: 600,
    md: 768,
    lg: 1024,
    xl: 1440,
  );

  //メディアクエリー
  $mediaquerys: (
    sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
    md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
    lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
    xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
  );

} @else {
  $breakpoints: (
    xl: 1440,
    lg: 1279,
    md: 767,
    sm: 600,
  );
  $mediaquerys: (
    sm: "screen and (max-width: #{map-get($breakpoints,'sm')}px)",
    md: "screen and (max-width: #{map-get($breakpoints,'md')}px)",
    lg: "screen and (max-width: #{map-get($breakpoints,'lg')}px)",
    xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
  );


}

// @include mq(){}で書くとブレークポイントが反映される（初期値はmd）
@mixin mq($mediaquery: md) {
  @media #{map-get($mediaquerys, $mediaquery)} {
    @content;
  }
}


@if $startFrom == sp {
  //pcで表示
  .pc {
    display: none;
    @include mq("md") {
      display: block;
    }
  }

  //モバイルのみ表示
  .sp {
    @include mq("md") {
      display: none;
    }
  }
} @else {
  //pcで表示
  .pc {
    display: block;
    @include mq("md") {
      display: none;
    }
  }

  //モバイルのみ表示
  .sp {
    display: none;
    @include mq("md") {
      display: block;
    }
  }
}