@use "global" as *;

.sub-head {
    padding: prem(170) 0 prem(80);
    background-color: $accent;

    @include mq(md) {
        padding: prem(70) 0 prem(17);
    }
}

.sub-head__inner {
    position: relative;
}

.sub-head__title-image {
    position: absolute;
    max-width: calc(505 / 1200 * 100%);
    right: prem(80);
    top: prem(-20);
    @include mq(md) {
        position: initial;
        right: initial;
        top: initial;
        max-width: 100%;
    }

}

.sub-head__title-image img {
    border-radius: prem(20);
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));

    @include mq(md) {
        border-radius: prem(10);
    }
}

.sub-head .sub-head__title {
    text-align: left;
    font-size: clamp(45px,vw(1200,64),64px);
    @include mq(md) {
        margin-top: prem(15);
    }
}

.sub-head .sub-head__title span {
    font-size: clamp(16px,vw(1200,20),20px);
}