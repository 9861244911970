@use "global" as *;

.sub-law{   
    margin-top: 108px;
    margin-bottom: 80px;
    @include mq(md){
        margin-top: prem(40);
    }
}

.sub-law__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    @include mq(md){
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}

.sub-law__title {
    padding-left: prem(20);
}

.sub-law__content-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: prem(800);
    width: 100%;
    @include mq(md){
        max-width: 100%;
        margin-left: 0;
    }
}

.sub-law__enactment {
    font-size: 12px;
    line-height: calc(21 / 12);
    text-align: right;
    margin-top: 40px;
    @include mq(md){
        margin-top: 20px;
        margin-right: 20px;
    }
}