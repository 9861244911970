@use "global" as *;

.access {
    margin-top: prem(80);
}

.access__wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    @include mq(md){
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}

.access__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: prem(800);
    width: 100%;
    @include mq(md){
        max-width: 100%;
        margin-left: 0;
    }
}

.access h2 {

    @include mq(md){
        margin: 0 prem(20);
    }
}

.access__map {
    margin-top: prem(40);
    width: 100%;
    height: prem(420);
}

.access__map iframe {
    width: 100%;
    height: prem(420);
}