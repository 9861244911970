@use "global" as *;

.result-sub__head {
    display: grid;
    grid-template-columns: 1fr 22.22%;
    align-items: center;

    @include mq(md) {
        grid-template-columns: 1fr;
    }
}

.result-sub:nth-of-type(3) .result-sub__head {
    grid-template-columns: 1fr 31.111%;

    @include mq(md) {
        grid-template-columns: 1fr;
    }
}

.result-sub__title {
    display: flex;
    align-items: end;

    @include mq(md) {
        display: block;
        text-align: center;
    }
}

.result-sub__title h3 {
    font-size: prem(28);

    @include mq(md) {
        font-size: prem(24);
        line-height: calc(36 / 24);
    }
}

.result-sub__title span {
    font-size: prem(12);
    line-height: calc(21 / 12);
    margin-left: prem(9);

    @include mq(md) {
        margin-left: 0;
    }
}

.result-sub__head a {
    transition: $delay all ease;

    @include mq(md) {
        max-width: calc(173 / 335 * 100%);
        margin: prem(20) auto 0;
    }
}

.result-sub__head a:hover {
    opacity: .6;
}

.result-sub:nth-of-type(3) .result-sub__head a {
    @include mq(md) {
        max-width: calc(197 / 335 * 100%);
        margin: prem(20) auto 0;
    }
}

.result-sub__content {
    background-color: #fff;
    border-radius: prem(20);
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));
    padding: prem(20) prem(117);
    margin-top: prem(20);

    @include mq(md) {
        padding: prem(10) prem(13) prem(20) prem(16);
        border-radius: prem(10);
    }
}

.result-sub__content--pv {
    padding: prem(20) prem(56);
}

.result-sub__container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: prem(8);
}

.result-sub__container span {
    display: inline-block;
    font-weight: bold;
    font-size: prem(20);
    line-height: calc(30 / 20);

    @include mq(md) {
        font-size: prem(16);
        line-height: calc(24 / 16);
        letter-spacing: -0.05em;
    }
}

.result-sub__number {
    font-size: prem(56);
    font-weight: 900;
    color: $accent;
    line-height: 1;

    @include mq(md) {
        font-size: prem(48);
        line-height: calc(56 / 48);
        letter-spacing: -0.05em;
    }
}