@use "global" as *;

.title {
    font-size: prem(64);
    line-height: 1;
    text-align: center;
    color: $accent;
    font-family: $title-font-family;
    font-weight: 400;
    text-transform: capitalize;
    @include mq(md){
        font-size: prem(48);
        text-align: left;
    }
}

.title span {
    font-family: $base-font-family;
    margin-top: prem(10);
    display: block;
    font-size: prem(20);
    line-height: calc(30 / 20);
    color: $black;
    font-weight: bold;
    @include mq(md){
        margin-top: 0;
        font-size: prem(16);
        text-align: left;
    }
}

.title--white {
    color: #fff;
}

.title--white span {
    color: #fff;
}