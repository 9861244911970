@use "global" as *;

.breadcrumb {
    margin-top: prem(29);
    @include mq(md){
        margin-top: prem(10);
    }
}



.breadcrumb span {
    font-size: prem(12);
    line-height: calc(21 / 12);
}

.breadcrumb a {
    color: $accent;
    transition: $delay all ease;
}

.breadcrumb a:hover {
    opacity: .6;
}