@use "global" as *;

.campaign-swiper .swiper {
    overflow: visible;
    padding-top: prem(40);
    @include mq(md){
        overflow-x: auto;
        overflow-y: hidden;
    }
}

.campaign-swiper .swiper {

}

.campaign-swiper__button-wrap {
    margin-top: prem(70);
    display: flex;
    justify-content: center;
    margin-left: 50%;
    @include mq(md){
        margin-left: 0;
        display: none;
    }
}


.campaign-swiper__button-wrap .swiper-button-prev,
.campaign-swiper__button-wrap .swiper-button-next {
    left: prem(100);
}

.campaign-swiper__button-wrap .swiper-button-next {

    margin-left: prem(10);
}

