@use "global" as *;

.button-prev {
    width: prem(50);
    height: prem(50);
    background-color: $accent;
    border-radius: 50%;
    border: 2px solid #fff;
    position: relative;
}

.swiper-button-next::after,
.swiper-button-prev:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mask-size: cover;
    mask-repeat: no-repeat;
    background-color: #fff;
}

.swiper-button-next::after {
    mask-image: url(../img/common/icon_arrow_right_slider.svg);
    width: prem(11);
    height: prem(17);
}

.swiper-button-prev:after {
    mask-image: url(../img/common/icon_arrow_left_slider.svg);
    width: prem(11);
    height: prem(17);
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    background-color: #D9D9D9;
}

.swiper-button-next.button-prev:hover,
.swiper-button-prev.button-prev:hover {
    background-color: #fff;
    border: 2px solid $accent;
}

.swiper-button-next.button-prev:hover::after,
.swiper-button-prev.button-prev:hover::after {
    background-color: $accent;
}