@use "global" as *;

.campaign-list {
    @include mq(md){
        padding: 0 prem(20);
    }
}

.campaign-list__item {
    width: max-content;
    @include mq(md){
        margin-right: prem(40);
    }
}

.campaign-list__item a {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 340px;
    overflow: hidden;
    @include mq(md){
        width: 200px;
    }
}

.campaign-list__item-img {
    width: 100%;
}

.campaign-list__item-img img {
    position:absolute;
    width: 100%;
    aspect-ratio: 360/186;
    object-fit: cover;
    border-radius: prem(20);
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));
    @include mq(md){
        border-radius: prem(10);
    }
}

.campaign-list__item-content {
    padding: prem(187) 0 0;
    @include mq(md){
        padding: prem(114) 0 0;
    }
}

.campaign-list__item-title {
    font-size: prem(16);
    font-weight: bold;
    line-height: calc(28 / 16);
    @include mq(md){
        font-size: prem(16);
    }
}

.campaign-list__item a:hover .campaign-list__item-title {
    color: $accent;
}

@include mq(md){
    .campaign-list__item a:hover .campaign-list__item-title {
        color: $black;
    }
}

.campaign-list__item-meta {
    margin-top: auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: prem(10) 0 0;
    @include mq(md){
        padding: prem(10) 0 0;
    }
}

.campaign-list__item-category {
    padding: prem(10);
    font-size: prem(14);
    line-height: 1;
    color: $accent;
    font-weight: bold;
    background-color: #E5F7FC;
    border-radius: prem(5);
    @include mq(md){
        font-size: prem(14);
    }
}