@use "global" as *;


.sns {
    padding: prem(40) 0 prem(60);
    background-color: #F5F5F5;
    text-align: center;
    @include mq(md){
        padding: prem(40) 0 prem(40);
    }
}

.sns h3 {
    font-size: prem(20);
    line-height: calc(30 / 20);
    font-weight: bold;

}

.sns__wrapper {
    margin-top: prem(40);
    display: flex;
    justify-content: center;
    gap: prem(60);
    @include mq(md){
        gap: prem(30);
        margin-top: prem(20);
    }
}

.sns__wrapper li {
    max-width: prem(80);
    @include mq(md){
        max-width: prem(60);
    }
}

.sns__wrapper li a {
    transition: $delay all ease;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));
}

.sns__wrapper li a:hover {
    opacity: .6;
}