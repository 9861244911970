@use "global" as *;

.about {
    margin: prem(40) 0 prem(62);
}

.about .about__inner {
    @include mq(md){
        padding: 0 calc(47 / 375 * 100%);
    }
}


.about h2 {
    max-width: prem(280);
    margin: 0 auto;
    @include mq(md){
        max-width: 100%;
    }
}

.about p {
    text-align: center;
    font-size: prem(16);
    font-weight: 500;
    line-height: calc(50 / 16);
    @include mq(md){
        line-height: calc(32 / 16);
        text-align: left;
    }
}

.about p:nth-of-type(1) {
    margin-top: prem(20);
    @include mq(md){
        margin-top: prem(40);
    }
}

.about p + p {
    @include mq(md){
        margin-top: prem(20);
    }
}