@use "global" as *;

.top-inner {
    padding-right: 20px;
    padding-left: 20px;
    width: 1160px;
    margin-right: auto;
    margin-left: auto;
    @include mq(md) {
        padding-right: $padding-sp;
        padding-left: $padding-sp;
        width: inherit;
    }
}