@use "global" as *;


.service-list__item {
    display: grid;
    grid-template-columns: 1fr 48.214%;
    gap: prem(40);
    padding: prem(60) 0 prem(40) prem(60);
    position: relative;

    @include mq(md) {
        grid-template-columns: 1fr;
        gap: 0;
        padding: 0;
    }
}

.service-list__item::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: prem(70);
    bottom: 0;
    background-color: #fff;
    border-radius: prem(20);

    @include mq(md) {
        right: 0;
        border-radius: prem(10);
    }
}

.service-list__image {
    order: 2;
    position: relative;
    aspect-ratio: 540 / 320;

    @include mq(md) {
        order: 1;
    }
}

.service-list__image img {
    border-radius: prem(20);
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));
    aspect-ratio: 540 / 320;

    @include mq(md) {
        border-radius: prem(10);
    }
}

.service-list__image::after {
    content: "";
    position: absolute;
    background-image: url(../img/common/img_service_kashimowimax.png);
    background-size: cover;
    background-repeat: no-repeat;
    bottom: prem(-87);
    left: 50%;
    transform: translateX(-50%);
    width: calc(249 / 540 * 100%);
    aspect-ratio: 249 / 220;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));

    @include mq(md) {
        bottom: prem(-54);
    }
}

.service-list__content {
    order: 1;
    position: relative;
    width: prem(480);

    @include mq(md) {
        order: 2;
        margin-top: prem(70);
        padding: 0 prem(20) prem(20);
        width: inherit;
    }
}

.service-list__item:nth-last-of-type(2) .service-list__content {
    @include mq(md) {
        margin-top: prem(40);
    }
}

.service-list__logo {
    width: calc(352 / 480 * 100%);

    @include mq(md) {
        margin: 0 auto;
    }
}

.service-list__content h3 {
    font-size: prem(28);
    line-height: calc(42 / 28);
    margin-top: prem(40);

    @include mq(md) {
        margin-top: prem(20);
        font-size: prem(24);
        line-height: calc(36 / 24);
    }
}

.service-list__text {
    font-size: prem(16);
    line-height: calc(28 / 16);

    @include mq(md) {
        margin-top: prem(10);
    }
}

.service-list__button {
    margin-top: prem(20);
}

.service-list__link {
    margin-top: prem(20);
    padding-left: prem(34.5);
    position: relative;
    font-weight: bold;
    transition: $delay all ease;
}

.service-list__link+.service-list__link {
    margin-top: prem(12);
}

.service-list__link::before {
    content: "";
    position: absolute;
    mask-image: url(../img/common/icon_arrow_right.svg);
    mask-size: cover;
    mask-repeat: no-repeat;
    background-color: $accent;
    top: 50%;
    left: prem(10);
    transform: translateY(-50%);
    width: prem(10);
    height: prem(15);
    transition: .3s;
}


.service-list__link:hover a {
    color: $accent;
    @include mq(md){
        color: $black;
    }
}

.service-list__item+.service-list__item {
    margin-top: prem(38);

    @include mq(md) {
        margin-top: prem(20);
    }
}

.service-list li:nth-of-type(2) .service-list__image::after {
    background-image: url(../img/common/img_service_kashimohikari.png);
    bottom: prem(-20);
    left: 50%;
    transform: translateX(-50%);
    width: calc(330 / 540 * 100%);
    aspect-ratio: 330 / 50;
}

.service-list li:nth-of-type(2) .service-list__logo {
    width: calc(414 / 480 * 100%);
}

.service-list li:nth-of-type(3) {
    padding-bottom: prem(100);
    @include mq(md){
        padding-bottom: prem(70);
    }
}

.service-list li:nth-of-type(3)::after {
    position: absolute;
    content: "※IPhone格安SIM通信は、東証プライム上場の(株)マーケットエンタープライズが運営する通信情報メディアです。";
    bottom: prem(40);
    left: 0;
    margin-left: prem(60);
    font-size: prem(12);
    line-height: calc(21 / 12);
    @include mq(md) {
        margin: 0 prem(20) 0;
        bottom: prem(20);
    }
}

.service-list li:nth-of-type(3) .service-list__logo {
    width: calc(396 / 480 * 100%);
}

.service-list li:nth-of-type(3) .service-list__image::after {

    background-image: url(../img/common/img_service_iphonekakuyasu.png);
    bottom: prem(-45);
    left: 50%;
    transform: translateX(-50%);
    width: calc(125 / 540 * 100%);
    aspect-ratio: 125 / 250;
}