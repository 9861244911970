@use "global" as *;

.service {
    background-color: $accent;
    padding: prem(80) 0 prem(80);


    @include mq(md){
        border-radius: prem(20) prem(20) 0 0;
        margin-top: prem(-20);
        padding: prem(40) 0 prem(40);
    }
}

.service__text {
    color: #fff;
    margin-top: prem(20);
    text-align: center;
    @include mq(md){
        text-align: left;
    }
}
.service__container {
    overflow-x: auto;
    overflow-y: hidden;
}

.service__list {
    margin-top: prem(40);
}
