@use "global" as *;

.result {
    padding: prem(80) 0 prem(80);
    overflow-x: auto;
    overflow-y: hidden;
    @include mq(md) {
        padding: prem(40) 0 prem(40);
    }
}

.result__inner {
    max-width: inherit;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: prem(40);
    padding: 0;

    @include mq(md) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        gap: prem(20);
    }
}


.result__ranking {
    grid-area: 1 / 1 / 3 / 2;
    background-color: #E5F7FC;
    padding: prem(20) prem(40) prem(60);
    border-radius: prem(20);

    @include mq(md) {
        grid-area: inherit;
        padding: prem(20);
        border-radius: prem(10);
    }
}

.result__lines {
    grid-area: 1 / 2 / 2 / 3;
    background-color: #E5F7FC;
    padding: prem(20) prem(40) prem(30);
    border-radius: prem(20);

    @include mq(md) {
        grid-area: inherit;
        padding: prem(20);
        border-radius: prem(10);
    }
}

.result__pv {
    grid-area: 2 / 2 / 3 / 3;
    background-color: #E5F7FC;
    padding: prem(20) prem(40) prem(30);
    border-radius: prem(20);

    @include mq(md) {
        grid-area: inherit;
        padding: prem(20);
        border-radius: prem(10);
    }
}

.result__ranking-head {
    display: grid;
    grid-template-columns: 1fr 22.22%;
    align-items: center;

    @include mq(md) {
        grid-template-columns: 1fr;
    }
}

.result__ranking-head h3 {
    font-size: prem(28);
    line-height: calc(42 / 28);

    @include mq(md) {
        font-size: prem(24);
        line-height: calc(36 / 24);
        text-align: center;
    }
}

.result__ranking-head a {
    transition: $delay all ease;

    @include mq(md) {
        max-width: calc(173 / 335 * 100%);
        margin: prem(20) auto 0;
    }
}

.result__ranking-head a:hover {
    opacity: .6;
}

.result__ranking-content {
    margin-top: prem(20);
}

.result__ranking-content img {
    border-radius: prem(20);
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));

    @include mq(md) {
        border-radius: prem(10);
    }
}

.result__ranking-notes {
    margin-top: prem(20);
    font-size: prem(12);
}

.count-size {
    font-size: prem(50);

}