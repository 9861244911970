@use "global" as *;

.header {
    height: prem(90);
    background-color: $accent;
    color: #fff;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    overflow-x: auto;
    overflow-y: hidden;
    @include mq(md) {
        height: prem(70);
        overflow-x: inherit;
        overflow-y: inherit;
    }
}

.header__inner {
    width:1200px;
    padding:0 20px;
    margin:0 auto;
    height: inherit;

    @include mq(md) {
        padding-left: prem(20);
        width:inherit;
    }
}

.header__wrapper {

    height: inherit;
    display: flex;
    justify-content: space-between;
}

.header__logo {
    height: inherit;
    display: flex;
    align-items: center;
    z-index: 999;
}

.header__logo span {
    font-size: prem(14);
    line-height: calc(21 / 14);
    margin-left: prem(10);
    font-weight: 500;

    @include mq("md") {
        font-size: prem(12);
        line-height: calc(16 / 12);
        margin-left: prem(20);
    }
}

.header__logo a {
    max-width: prem(150);
    height: inherit;
    display: flex;
    align-items: center;

    @include mq("md") {
        max-width: prem(101);
    }
}

.header__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: $delay all ease;
}

.header__logo a:hover img {
    opacity: .6;
}


.header__nav {
    display: block;
    height: inherit;

    @include mq(md) {
        display: none;
    }
}

.header__nav-items {
    display: flex;
    height: inherit;

    @include mq(md) {
        display: block;
    }
}

.header__nav-item {
    height: inherit;
    display: flex;
    align-items: center;
    margin-left:10px;
}

.header__nav-item a {
    padding: prem(15) prem(20) prem(15) prem(30);
    border-radius: prem(50);
    height: initial;
    display: flex;
    align-items: center;
    font-size: prem(16);
    font-weight: 500;
    line-height: 1;
    color: #fff;
    position: relative;
    transition: $delay all ease;
    border: 2px solid #fff;
    border-color: transparent;
}

.header__nav-item a:hover {
    border-color: #fff;
}

.header__nav-item a::before {
    position: absolute;
    content: "";
    width: prem(10);
    height: prem(10);
    border-radius: 50%;
    background-color: #fff;
    top: 50%;
    left: prem(10);
    transform: translateY(-50%);
}

.header__nav-item--now span {
    padding: prem(15) prem(20) prem(15) prem(30);
    border-radius: prem(50);
    height: initial;
    display: flex;
    align-items: center;
    font-size: prem(16);
    font-weight: 500;
    line-height: 1;
    color: $accent;
    position: relative;
    background-color: #fff;
    border-radius: prem(50);
}

.header__nav-item--now span::before {
    position: absolute;
    content: "";
    width: prem(10);
    height: prem(10);
    border-radius: 50%;
    background-color: $accent;
    top: 50%;
    left: prem(10);
    transform: translateY(-50%);
}

.header__nav-item.header__nav-item--contact a {
    padding: prem(15) prem(20);
    height: initial;
    color: #fff;
    background-color: #323232;
    border-radius: prem(50);
}

.header__nav-item.header__nav-item--contact a::before {
    display: none;
}

.header__hamburger {
    display: none;
    margin: prem(10) 0;
    position: relative;
    z-index: 999;
    width: prem(50);
    height: prem(50);
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;

    @include mq(md) {
        display: block;
    }
}

.header__hamburger-area {
    transition: all .4s;
}

.header__hamburger-area span {
    display: inline-block;
    transition: all .4s;
    position: absolute;
    left: 14px;
    height: 4px;
    border-radius: 2px;
    background-color: $accent;
    width: 45%;
}

.header__hamburger-area span:nth-of-type(1) {
    top: 15px;
}

.header__hamburger-area span:nth-of-type(2) {
    top: 23px;
}

.header__hamburger-area span:nth-of-type(3) {
    top: 31px;
}

.header__hamburger.is-open .header__hamburger-area {
    transform: rotateX(360deg);
}

.header__hamburger.is-open span:nth-of-type(1) {
    top: 18px;
    left: 15px;
    transform: translateY(6px) rotate(-135deg);
    width: 40%;
}

.header__hamburger.is-open span:nth-of-type(2) {
    opacity: 0;
}

.header__hamburger.is-open span:nth-of-type(3) {
    top: 30px;
    left: 15px;
    transform: translateY(-6px) rotate(135deg);
    width: 40%;
}

.header__drawer {
    padding: prem(90) prem(20);
    display: none;
    position: absolute;
    z-index: 900;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $accent;
    overflow-y: scroll;
    scrollbar-width: none;
}

.header__drawer::-webkit-scrollbar {
    display: none;
}

.header__drawer-title {
    font-family: $title-font-family;
    font-size: prem(48);
    line-height: 1;
}

.header__drawer-items {
    margin-top: prem(30);
}

.header__drawer-item {
    border-bottom: dashed 2px #E5F7FC;
}

.header__drawer-item:nth-of-type(1) {
    border-top: dashed 2px #E5F7FC;
}

.header__drawer-item:last-child {
    border-bottom: none
}

.header__drawer-item a {
    padding: prem(20) prem(0) prem(20) prem(40);
    display: block;
    font-size: prem(20);
    font-weight: bold;
    line-height: 1;
    position: relative;
}

.header__drawer-item a::before {
    position: absolute;
    content: "";
    width: prem(10);
    height: prem(10);
    border-radius: 50%;
    background-color: #fff;
    top: 50%;
    left: prem(10);
    transform: translateY(-50%);
}

.header__drawer-item.header__drawer-item--contact {
    margin-top: prem(30);
}

.header__drawer-item.header__drawer-item--contact a {
    padding: prem(15) prem(20);
    height: initial;
    color: #fff;
    background-color: #323232;
    border-radius: prem(50);
    text-align: center;
}

.header__drawer-item.header__drawer-item--contact a::before {
    display: none;
}

.header__sub-drawer-items {
    margin-top: prem(20);
}

.header__sub-drawer-item a {
    padding-left: prem(35);
    font-size: prem(16);
    font-weight: bold;
    position: relative;
    line-height: calc(28 / 16);
}

.header__sub-drawer-item a::before {
    content: "";
    position: absolute;
    mask-image: url(../img/common/icon_arrow_right.svg);
    mask-size: cover;
    mask-repeat: no-repeat;
    background-color: #fff;
    top: 50%;
    left: prem(10);
    transform: translateY(-50%);
    width: prem(10);
    height: prem(15);
}

.header__sub-drawer-item + .header__sub-drawer-item {
    margin-top: prem(10);
}