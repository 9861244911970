@use "global" as *;

.sub-not-found {
    margin-top: 60px;
    margin-bottom: 80px;
    @include mq(md){
        margin-top: 20px;
        margin-bottom: 40px;
    }
}

.sub-not-found__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;

    @include mq(md) {
        max-width: 100%;
        margin: 0;
        padding: 0 20px;
    }
}

.sub-not-found__inner p {
    text-align: center;
    line-height: calc(28 / 16);
    @include mq(md) {
        text-align: left;
    }
}

.sub-not-found__button {
    margin-top: 40px;
    text-align: center;
    @include mq(md) {
        margin-top: 20px;
        text-align: left;
    }
}

.sub-not-found__inner h2 {
    margin-top: 60px;
    font-size: 28px;
    line-height: calc(42 / 28);
    @include mq(md) {
        margin-top: 40px;
        font-size: 24px;
    }
}

.sub-not-found__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 40px; 
    margin-top: 20px;
    @include mq(md) {
        grid-template-columns: 1fr;
        gap: 20px; 
    }
}

.sub-not-found__item {
    background-color: #E5F7FC;
    border-radius: 10px;
    padding: 20px 20px 18px;
    display: block;
}

.sub-not-found__item img {
    border-radius: 10px;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));
}

.sub-not-found__item p {
    margin-top: 10px;
    font-size: 16px;
    line-height: calc(28 / 16);
    text-align: left;
    font-weight: bold;
}

.sub-not-found__item:hover p {
    color: $accent;
    @include mq(md){
        color: $black;
    }
}