@use "global" as *;

.company-profile {
    border-top: 2px solid #F5F5F5;
}

.company-profile__list {
    padding: prem(30) prem(31);
    display: flex;
    font-size: prem(16);
    line-height: calc(28 / 16);
    border-bottom: 2px solid #F5F5F5;

    @include mq(md) {
        padding: prem(15) prem(20);
        display: block;

    }
}

.company-profile__term {
    max-width: prem(160);
    width: 100%;
    flex-shrink: 0;

    @include mq(md) {
        max-width: 100%;
        color: #969696;
    }
}

.company-profile__description {
    @include mq(md) {
        margin-top: prem(5);
    }
}

.company-profile__description a {
    color: $accent;
    transition: $delay all ease;
}

.company-profile__description a:hover {
    opacity: .6;
}