@use "global" as *;

.scroll-swiper .swiper-wrapper {
    transition-timing-function: linear !important;
}

.scroll-swiper .swiper-slide {
    width: max-content;
    flex-shrink: 0;
    height: 100%;
    transition-property: transform;
}

.scroll-swiper .slide {
    width: prem(390);
    @include mq(md){
        width: prem(270);
    }
}

.scroll-swiper .slide-media {
    overflow: hidden;
    height: prem(260);
    @include mq(md){
        height: prem(180);
    }
}

.scroll-swiper .swiper-slide img {
    object-fit: cover;
}