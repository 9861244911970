@use "sass:math";

// インナー幅設定
// コンテンツのインナー幅（最も共通の幅が多い部分）
$inner: 1160px;
// ※キュンパス幅ではなくインナーの幅を指す

// 余白
$padding-pc: 80px;
$padding-sp: 20px;

////////////////////
// フォント設定
////////////////////

$base-font-family: 'Noto Sans JP', sans-serif;
$title-font-family: 'Monoton', sans-serif;
// ※検証ツールでしっかり反映されているか確認する


// フォントウェイト
$regular: 300;
$medium: 500;
$bold: 700;


$delay: 0.5s;

////////////////////
// 色の指定
////////////////////

//色指定（共通性のあるものは出来る限り指定すると良い）
$black: #1E1E1E;
$white: #fff;
$accent: #00AFDD;


body {
  font-family: $base-font-family;
  color: $black;
}