@use "global" as *;

.btn {
    padding: prem(13) prem(45) prem(13) prem(30);
    position: relative;
    display: inline-block;
    font-size: prem(15);
    line-height: calc(28 / 16);
    letter-spacing: 0.05em;
    border: 2px solid $accent;
    box-sizing: content-box;
    background-color: $accent;
    border-radius: 50px;
    color: #fff;
    font-weight: bold;
    transition: $delay all ease;
}

.btn::before {
    content: "";
    position: absolute;
    mask-image: url(../img/common/icon_arrow_right.svg);
    mask-size: cover;
    mask-repeat: no-repeat;
    background-color: #fff;
    top: 50%;
    right: prem(27);
    transform: translateY(-50%);
    width: prem(10);
    height: prem(15);
    transition: $delay all ease;
}

.btn:hover {
    color: $accent;
    background-color: #fff;
}

.btn:hover::before {
    background-color: $accent;
}

@include mq(md) {
    .btn:hover {
        color: #fff;
        background-color: $accent;
    }
}

@include mq(md) {
    .btn:hover::before {
        background-color: #fff;
    }
}