@use "global" as *;

.news {
    margin: prem(40) 0 0;
    background: linear-gradient(180deg, transparent 0%, transparent 85%, $accent 85%, $accent 100%);
}

.news__inner {
    @include mq(md) {
        padding: 0;
    }
}

.news__content {
    padding: prem(60);
    background-color: #f5f5f5;
    border-radius: prem(20);

    @include mq(md) {
        border-radius: prem(20) prem(20) 0 0;
        padding: prem(20) 0 prem(70);
    }
}

.news__title {
    font-size: prem(40);
    line-height: 1;
    font-weight: 900;

    @include mq(md) {
        margin-left: prem(20);
        font-size: prem(32);
    }
}

.news__title span {
    margin-left: prem(27);
    font-size: prem(20);
    line-height: calc(30 / 20);
    font-weight: bold;

    @include mq(md) {
        font-size: prem(16);
        margin-left: prem(10);
    }
}

.news__list {
    margin-top: prem(22);
    background-color: #fff;
    border-radius: prem(20);
    max-height: prem(340);
    overflow-x: hidden;
    overflow-y: auto;

    @include mq(md) {
        border-radius: prem(10);
        max-height: prem(312);
    }
}

.news-list__item {
    border-bottom: 2px solid #F5F5F5;
    position: relative;
}

.news-list__item a {
    padding: prem(20) prem(40) prem(20) 0;
    display: grid;
    grid-template-columns: prem(145) 1fr;
    align-items: center;

    @include mq(md) {
        grid-template-columns: 1fr;
        padding: prem(20) prem(45) prem(15) prem(20);
    }
}

.news-list__item a:hover {
    color: $accent;
    @include mq(md){
        color: $black;
    }
}

.news-list__item a:after {
    content: "";
    position: absolute;
    mask-image: url(../img/common/icon_arrow_right.svg);
    mask-size: cover;
    mask-repeat: no-repeat;
    background-color: $accent;
    top: 50%;
    right: prem(27);
    transform: translateY(-50%);
    width: prem(10);
    height: prem(16);
    transition: .3s;
    width: 0.625rem;
}

.news-list__item div {
    padding: prem(20) 0;
    display: grid;
    grid-template-columns: prem(145) 1fr;
    align-items: center;
    transition: $delay all ease;

    @include mq(md) {
        grid-template-columns: 1fr;
        padding: prem(20) prem(45) prem(15) prem(20);
    }
}

.news-list__item-date {
    font-size: prem(16);
    line-height: 1;
    margin-left: prem(28);
    color: #969696;
    font-weight: bold;

    @include mq(md) {
        margin-left: 0;
    }
}

.news-list__item-title {
    font-size: prem(16);
    line-height: 1.5;

    @include mq(md) {
        margin-top: prem(5);
        margin-left: 0;
    }
}

.news-list__item:nth-of-type(1) .news-list__item-title {
    font-weight: bold;
}