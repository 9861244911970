@use "global" as *;

.footer {
    padding: prem(80) 0 prem(41);
    background-color: #323232;

    @include mq(md) {
        padding: prem(45) 0;

    }
}

.footer__inner {
    margin: auto;
    width: 100%;
    padding: 0 prem(25);
    max-width: prem(1250);

    @include mq(md) {
        padding: 0 prem(15);
        max-width: prem(630);
    }
}

.footer__wrap {
    display: flex;
    justify-content: space-between;

    @include mq(md) {
        display: block;
    }
}

.footer__logo {
    margin: initial;
    display: flex;
    align-items: center;

    @include mq(md) {
        margin: 0 auto;
        justify-content: center;
    }
}

.footer__logo a {
    padding: prem(15) 0;
    display: block;
    max-width: prem(150);
    transition: $delay all ease;

    @include mq(md) {
        padding: 0;
    }
}

.footer__logo a:hover {
    opacity: .6;
}

.footer__logo p {
    max-width: prem(112);
    font-size: prem(14);
    line-height: calc(21 /14);
    color: #fff;
    margin-left: prem(20);
}

.footer__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.footer__nav {
    @include mq(md) {
        margin-top: prem(20);
    }
}

.footer__nav-items {
    display: flex;
    align-items: center;

    @include mq(md) {
        display: block;
    }
}

.footer__nav-item a {
    padding: prem(10);
    position: relative;
    display: block;
    font-size: prem(16);
    line-height: 1;
    text-align: center;
    color: #fff;
    transition: $delay all ease;

    @include mq(md) {
        padding: prem(12) 0;
    }
}

.footer__nav-item a:hover {
    opacity: .6;
}

.footer__copyright {
    margin-top: prem(18);
    display: block;
    font-size: prem(12);
    line-height: 1;
    letter-spacing: 0.04em;
    text-align: right;
    color: #969696;
    line-height: calc(21 / 12);

    @include mq(md) {
        margin-top: prem(20);
        text-align: center;
    }
}