@charset "UTF-8";
body {
  font-family: "Noto Sans JP", sans-serif;
  color: #1E1E1E;
}

.pc {
  display: block;
}
@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

.sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

/* リキッドレイアウト対応 */
html {
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* フォームリセット */
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}

.sub-not-found {
  margin-top: 60px;
  margin-bottom: 80px;
}
@media screen and (max-width: 767px) {
  .sub-not-found {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.sub-not-found__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .sub-not-found__inner {
    max-width: 100%;
    margin: 0;
    padding: 0 20px;
  }
}

.sub-not-found__inner p {
  text-align: center;
  line-height: 1.75;
}
@media screen and (max-width: 767px) {
  .sub-not-found__inner p {
    text-align: left;
  }
}

.sub-not-found__button {
  margin-top: 40px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .sub-not-found__button {
    margin-top: 20px;
    text-align: left;
  }
}

.sub-not-found__inner h2 {
  margin-top: 60px;
  font-size: 28px;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .sub-not-found__inner h2 {
    margin-top: 40px;
    font-size: 24px;
  }
}

.sub-not-found__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 40px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .sub-not-found__list {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.sub-not-found__item {
  background-color: #E5F7FC;
  border-radius: 10px;
  padding: 20px 20px 18px;
  display: block;
}

.sub-not-found__item img {
  border-radius: 10px;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
}

.sub-not-found__item p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  font-weight: bold;
}

.sub-not-found__item:hover p {
  color: #00AFDD;
}
@media screen and (max-width: 767px) {
  .sub-not-found__item:hover p {
    color: #1E1E1E;
  }
}

.about {
  margin: 2.5rem 0 3.875rem;
}

@media screen and (max-width: 767px) {
  .about .about__inner {
    padding: 0 12.5333333333%;
  }
}

.about h2 {
  max-width: 17.5rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .about h2 {
    max-width: 100%;
  }
}

.about p {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 3.125;
}
@media screen and (max-width: 767px) {
  .about p {
    line-height: 2;
    text-align: left;
  }
}

.about p:nth-of-type(1) {
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .about p:nth-of-type(1) {
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 767px) {
  .about p + p {
    margin-top: 1.25rem;
  }
}

.access {
  margin-top: 5rem;
}

.access__wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
@media screen and (max-width: 767px) {
  .access__wrapper {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}

.access__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .access__inner {
    max-width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .access h2 {
    margin: 0 1.25rem;
  }
}

.access__map {
  margin-top: 2.5rem;
  width: 100%;
  height: 26.25rem;
}

.access__map iframe {
  width: 100%;
  height: 26.25rem;
}

.breadcrumb {
  margin-top: 1.8125rem;
}
@media screen and (max-width: 767px) {
  .breadcrumb {
    margin-top: 0.625rem;
  }
}

.breadcrumb span {
  font-size: 0.75rem;
  line-height: 1.75;
}

.breadcrumb a {
  color: #00AFDD;
  transition: 0.5s all ease;
}

.breadcrumb a:hover {
  opacity: 0.6;
}

.btn {
  padding: 0.8125rem 2.8125rem 0.8125rem 1.875rem;
  position: relative;
  display: inline-block;
  font-size: 0.9375rem;
  line-height: 1.75;
  letter-spacing: 0.05em;
  border: 2px solid #00AFDD;
  box-sizing: content-box;
  background-color: #00AFDD;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  transition: 0.5s all ease;
}

.btn::before {
  content: "";
  position: absolute;
  mask-image: url(../img/common/icon_arrow_right.svg);
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: #fff;
  top: 50%;
  right: 1.6875rem;
  transform: translateY(-50%);
  width: 0.625rem;
  height: 0.9375rem;
  transition: 0.5s all ease;
}

.btn:hover {
  color: #00AFDD;
  background-color: #fff;
}

.btn:hover::before {
  background-color: #00AFDD;
}

@media screen and (max-width: 767px) {
  .btn:hover {
    color: #fff;
    background-color: #00AFDD;
  }
}
@media screen and (max-width: 767px) {
  .btn:hover::before {
    background-color: #fff;
  }
}
.button-prev {
  width: 3.125rem;
  height: 3.125rem;
  background-color: #00AFDD;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
}

.swiper-button-next::after,
.swiper-button-prev:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: #fff;
}

.swiper-button-next::after {
  mask-image: url(../img/common/icon_arrow_right_slider.svg);
  width: 0.6875rem;
  height: 1.0625rem;
}

.swiper-button-prev:after {
  mask-image: url(../img/common/icon_arrow_left_slider.svg);
  width: 0.6875rem;
  height: 1.0625rem;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  background-color: #D9D9D9;
}

.swiper-button-next.button-prev:hover,
.swiper-button-prev.button-prev:hover {
  background-color: #fff;
  border: 2px solid #00AFDD;
}

.swiper-button-next.button-prev:hover::after,
.swiper-button-prev.button-prev:hover::after {
  background-color: #00AFDD;
}

.campaign-swiper .swiper {
  overflow: visible;
  padding-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .campaign-swiper .swiper {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.campaign-swiper__button-wrap {
  margin-top: 4.375rem;
  display: flex;
  justify-content: center;
  margin-left: 50%;
}
@media screen and (max-width: 767px) {
  .campaign-swiper__button-wrap {
    margin-left: 0;
    display: none;
  }
}

.campaign-swiper__button-wrap .swiper-button-prev,
.campaign-swiper__button-wrap .swiper-button-next {
  left: 6.25rem;
}

.campaign-swiper__button-wrap .swiper-button-next {
  margin-left: 0.625rem;
}

.campaign {
  margin-top: 1.875rem;
  overflow-x: hidden;
}
@media screen and (max-width: 767px) {
  .campaign {
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 767px) {
  .campaign__title {
    padding: 0 1.25rem;
  }
}

.campaign__text {
  font-size: 1rem;
  line-height: 1.75;
  text-align: center;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .campaign__text {
    text-align: left;
    padding: 0 1.25rem;
  }
}

.campaign__inner {
  padding: 0 5rem;
  width: 100%;
  margin-right: calc(50% - 50vw);
}
@media screen and (max-width: 767px) {
  .campaign__inner {
    padding: 0;
  }
}

.campaign__swiper {
  overflow: visible;
  max-width: 1160px;
  padding: 0 1.25rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .campaign__swiper {
    max-width: initial;
    padding: initial;
    margin: initial;
  }
}

@media screen and (max-width: 767px) {
  .campaign-list {
    padding: 0 1.25rem;
  }
}

.campaign-list__item {
  width: max-content;
}
@media screen and (max-width: 767px) {
  .campaign-list__item {
    margin-right: 2.5rem;
  }
}

.campaign-list__item a {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 340px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .campaign-list__item a {
    width: 200px;
  }
}

.campaign-list__item-img {
  width: 100%;
}

.campaign-list__item-img img {
  position: absolute;
  width: 100%;
  aspect-ratio: 360/186;
  object-fit: cover;
  border-radius: 1.25rem;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
}
@media screen and (max-width: 767px) {
  .campaign-list__item-img img {
    border-radius: 0.625rem;
  }
}

.campaign-list__item-content {
  padding: 11.6875rem 0 0;
}
@media screen and (max-width: 767px) {
  .campaign-list__item-content {
    padding: 7.125rem 0 0;
  }
}

.campaign-list__item-title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.75;
}
@media screen and (max-width: 767px) {
  .campaign-list__item-title {
    font-size: 1rem;
  }
}

.campaign-list__item a:hover .campaign-list__item-title {
  color: #00AFDD;
}

@media screen and (max-width: 767px) {
  .campaign-list__item a:hover .campaign-list__item-title {
    color: #1E1E1E;
  }
}
.campaign-list__item-meta {
  margin-top: auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0 0;
}
@media screen and (max-width: 767px) {
  .campaign-list__item-meta {
    padding: 0.625rem 0 0;
  }
}

.campaign-list__item-category {
  padding: 0.625rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #00AFDD;
  font-weight: bold;
  background-color: #E5F7FC;
  border-radius: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .campaign-list__item-category {
    font-size: 0.875rem;
  }
}

.company-profile {
  border-top: 2px solid #F5F5F5;
}

.company-profile__list {
  padding: 1.875rem 1.9375rem;
  display: flex;
  font-size: 1rem;
  line-height: 1.75;
  border-bottom: 2px solid #F5F5F5;
}
@media screen and (max-width: 767px) {
  .company-profile__list {
    padding: 0.9375rem 1.25rem;
    display: block;
  }
}

.company-profile__term {
  max-width: 10rem;
  width: 100%;
  flex-shrink: 0;
}
@media screen and (max-width: 767px) {
  .company-profile__term {
    max-width: 100%;
    color: #969696;
  }
}

@media screen and (max-width: 767px) {
  .company-profile__description {
    margin-top: 0.3125rem;
  }
}

.company-profile__description a {
  color: #00AFDD;
  transition: 0.5s all ease;
}

.company-profile__description a:hover {
  opacity: 0.6;
}

.footer {
  padding: 5rem 0 2.5625rem;
  background-color: #323232;
}
@media screen and (max-width: 767px) {
  .footer {
    padding: 2.8125rem 0;
  }
}

.footer__inner {
  margin: auto;
  width: 100%;
  padding: 0 1.5625rem;
  max-width: 78.125rem;
}
@media screen and (max-width: 767px) {
  .footer__inner {
    padding: 0 0.9375rem;
    max-width: 39.375rem;
  }
}

.footer__wrap {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer__wrap {
    display: block;
  }
}

.footer__logo {
  margin: initial;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer__logo {
    margin: 0 auto;
    justify-content: center;
  }
}

.footer__logo a {
  padding: 0.9375rem 0;
  display: block;
  max-width: 9.375rem;
  transition: 0.5s all ease;
}
@media screen and (max-width: 767px) {
  .footer__logo a {
    padding: 0;
  }
}

.footer__logo a:hover {
  opacity: 0.6;
}

.footer__logo p {
  max-width: 7rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  margin-left: 1.25rem;
}

.footer__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 767px) {
  .footer__nav {
    margin-top: 1.25rem;
  }
}

.footer__nav-items {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer__nav-items {
    display: block;
  }
}

.footer__nav-item a {
  padding: 0.625rem;
  position: relative;
  display: block;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  color: #fff;
  transition: 0.5s all ease;
}
@media screen and (max-width: 767px) {
  .footer__nav-item a {
    padding: 0.75rem 0;
  }
}

.footer__nav-item a:hover {
  opacity: 0.6;
}

.footer__copyright {
  margin-top: 1.125rem;
  display: block;
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: 0.04em;
  text-align: right;
  color: #969696;
  line-height: 1.75;
}
@media screen and (max-width: 767px) {
  .footer__copyright {
    margin-top: 1.25rem;
    text-align: center;
  }
}

.fv-swiper {
  position: relative;
}

.fv-swiper .swiper {
  padding-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .fv-swiper .swiper {
    padding-bottom: 0;
  }
}

.fv-swiper__main .swiper-slide {
  transform: scale(0.822);
}

.fv-swiper__main .swiper-slide-active {
  transform: scale(1);
}
@media screen and (max-width: 767px) {
  .fv-swiper__main .swiper-slide-active {
    width: 100%;
  }
}

.fv-swiper__main .swiper-wrapper {
  transition-timing-function: linear;
}

.fv-swiper__main .swiper-slide {
  max-width: 53.9375rem;
}

.fv-swiper__main .swiper-slide a {
  transition: 0.5s all ease;
  display: inline-block;
}

.fv-swiper__main .swiper-slide a:hover img {
  border: 4px solid #fff;
}

.fv-swiper__main .swiper-slide img,
.fv-swiper__thumbnail .swiper-slide img {
  width: 100%;
  aspect-ratio: 863/486;
  max-width: 53.9375rem;
  object-fit: cover;
  border-radius: 1.25rem;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
}
@media screen and (max-width: 767px) {
  .fv-swiper__main .swiper-slide img,
  .fv-swiper__thumbnail .swiper-slide img {
    border-radius: 0.625rem;
  }
}

.fv-swiper .swiper-pagination {
  bottom: 4.0625rem;
  margin-top: -0.625rem;
}
@media screen and (max-width: 767px) {
  .fv-swiper .swiper-pagination {
    bottom: 7.5rem;
  }
}

.fv-swiper .swiper-pagination .swiper-pagination-bullet {
  background: #00AFDD;
}

.fv-swiper .swiper-button-next {
  width: 3.125rem;
  height: 3.125rem;
  background-color: #00AFDD;
  border-radius: 50%;
  border: 2px solid #fff;
  right: 6.25rem;
}

.fv-swiper .swiper-button-prev {
  width: 3.125rem;
  height: 3.125rem;
  background-color: #00AFDD;
  border-radius: 50%;
  border: 2px solid #fff;
  left: 6.25rem;
}

.fv-swiper .swiper-button-next:hover,
.fv-swiper .swiper-button-prev:hover {
  background-color: #fff;
  border: 2px solid #00AFDD;
}

.fv-swiper .swiper-button-next:hover::after,
.fv-swiper .swiper-button-prev:hover::after {
  background-color: #00AFDD;
}

.fv-swiper .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
}

.fv-swiper .swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "";
}

.swiper-pagination-bullets {
  position: unset;
  top: unset;
  bottom: 0px;
  left: 0;
  right: 0;
}

.swiper-pagination-bullet {
  margin: 0 5px;
  width: 0.875rem;
  height: 0.875rem;
}

.fv-swiper__thumbnail {
  padding: 0.625rem;
}

.fv-swiper__thumbnail .swiper-slide {
  cursor: pointer;
}

.fv-swiper__thumbnail .swiper-slide img {
  filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.fv-swiper__thumbnail .swiper-slide.swiper-slide-thumb-active img {
  border: 2px solid #fff;
  box-sizing: border-box;
}

.fv {
  padding-top: 6.875rem;
  background: linear-gradient(180deg, #00AFDD 0%, #00AFDD 60%, transparent 60%, transparent 100%);
}
@media screen and (max-width: 767px) {
  .fv {
    background-color: #00AFDD;
    padding-top: 5rem;
  }
}

.fv__inner {
  margin: auto;
  width: 100%;
}

.header {
  height: 5.625rem;
  background-color: #00AFDD;
  color: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden;
}
@media screen and (max-width: 767px) {
  .header {
    height: 4.375rem;
    overflow-x: inherit;
    overflow-y: inherit;
  }
}

.header__inner {
  width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  height: inherit;
}
@media screen and (max-width: 767px) {
  .header__inner {
    padding-left: 1.25rem;
    width: inherit;
  }
}

.header__wrapper {
  height: inherit;
  display: flex;
  justify-content: space-between;
}

.header__logo {
  height: inherit;
  display: flex;
  align-items: center;
  z-index: 999;
}

.header__logo span {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-left: 0.625rem;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .header__logo span {
    font-size: 0.75rem;
    line-height: 1.3333333333;
    margin-left: 1.25rem;
  }
}

.header__logo a {
  max-width: 9.375rem;
  height: inherit;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .header__logo a {
    max-width: 6.3125rem;
  }
}

.header__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s all ease;
}

.header__logo a:hover img {
  opacity: 0.6;
}

.header__nav {
  display: block;
  height: inherit;
}
@media screen and (max-width: 767px) {
  .header__nav {
    display: none;
  }
}

.header__nav-items {
  display: flex;
  height: inherit;
}
@media screen and (max-width: 767px) {
  .header__nav-items {
    display: block;
  }
}

.header__nav-item {
  height: inherit;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.header__nav-item a {
  padding: 0.9375rem 1.25rem 0.9375rem 1.875rem;
  border-radius: 3.125rem;
  height: initial;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  position: relative;
  transition: 0.5s all ease;
  border: 2px solid #fff;
  border-color: transparent;
}

.header__nav-item a:hover {
  border-color: #fff;
}

.header__nav-item a::before {
  position: absolute;
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: #fff;
  top: 50%;
  left: 0.625rem;
  transform: translateY(-50%);
}

.header__nav-item--now span {
  padding: 0.9375rem 1.25rem 0.9375rem 1.875rem;
  border-radius: 3.125rem;
  height: initial;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #00AFDD;
  position: relative;
  background-color: #fff;
  border-radius: 3.125rem;
}

.header__nav-item--now span::before {
  position: absolute;
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: #00AFDD;
  top: 50%;
  left: 0.625rem;
  transform: translateY(-50%);
}

.header__nav-item.header__nav-item--contact a {
  padding: 0.9375rem 1.25rem;
  height: initial;
  color: #fff;
  background-color: #323232;
  border-radius: 3.125rem;
}

.header__nav-item.header__nav-item--contact a::before {
  display: none;
}

.header__hamburger {
  display: none;
  margin: 0.625rem 0;
  position: relative;
  z-index: 999;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .header__hamburger {
    display: block;
  }
}

.header__hamburger-area {
  transition: all 0.4s;
}

.header__hamburger-area span {
  display: inline-block;
  transition: all 0.4s;
  position: absolute;
  left: 14px;
  height: 4px;
  border-radius: 2px;
  background-color: #00AFDD;
  width: 45%;
}

.header__hamburger-area span:nth-of-type(1) {
  top: 15px;
}

.header__hamburger-area span:nth-of-type(2) {
  top: 23px;
}

.header__hamburger-area span:nth-of-type(3) {
  top: 31px;
}

.header__hamburger.is-open .header__hamburger-area {
  transform: rotateX(360deg);
}

.header__hamburger.is-open span:nth-of-type(1) {
  top: 18px;
  left: 15px;
  transform: translateY(6px) rotate(-135deg);
  width: 40%;
}

.header__hamburger.is-open span:nth-of-type(2) {
  opacity: 0;
}

.header__hamburger.is-open span:nth-of-type(3) {
  top: 30px;
  left: 15px;
  transform: translateY(-6px) rotate(135deg);
  width: 40%;
}

.header__drawer {
  padding: 5.625rem 1.25rem;
  display: none;
  position: absolute;
  z-index: 900;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00AFDD;
  overflow-y: scroll;
  scrollbar-width: none;
}

.header__drawer::-webkit-scrollbar {
  display: none;
}

.header__drawer-title {
  font-family: "Monoton", sans-serif;
  font-size: 3rem;
  line-height: 1;
}

.header__drawer-items {
  margin-top: 1.875rem;
}

.header__drawer-item {
  border-bottom: dashed 2px #E5F7FC;
}

.header__drawer-item:nth-of-type(1) {
  border-top: dashed 2px #E5F7FC;
}

.header__drawer-item:last-child {
  border-bottom: none;
}

.header__drawer-item a {
  padding: 1.25rem 0rem 1.25rem 2.5rem;
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1;
  position: relative;
}

.header__drawer-item a::before {
  position: absolute;
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: #fff;
  top: 50%;
  left: 0.625rem;
  transform: translateY(-50%);
}

.header__drawer-item.header__drawer-item--contact {
  margin-top: 1.875rem;
}

.header__drawer-item.header__drawer-item--contact a {
  padding: 0.9375rem 1.25rem;
  height: initial;
  color: #fff;
  background-color: #323232;
  border-radius: 3.125rem;
  text-align: center;
}

.header__drawer-item.header__drawer-item--contact a::before {
  display: none;
}

.header__sub-drawer-items {
  margin-top: 1.25rem;
}

.header__sub-drawer-item a {
  padding-left: 2.1875rem;
  font-size: 1rem;
  font-weight: bold;
  position: relative;
  line-height: 1.75;
}

.header__sub-drawer-item a::before {
  content: "";
  position: absolute;
  mask-image: url(../img/common/icon_arrow_right.svg);
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: #fff;
  top: 50%;
  left: 0.625rem;
  transform: translateY(-50%);
  width: 0.625rem;
  height: 0.9375rem;
}

.header__sub-drawer-item + .header__sub-drawer-item {
  margin-top: 0.625rem;
}

.inner {
  max-width: 1320px;
  padding-right: 80px;
  padding-left: 80px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .inner {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.law-profile {
  border-top: 2px solid #F5F5F5;
}

.law-profile__list {
  padding: 1.875rem 1.9375rem;
  display: flex;
  font-size: 1rem;
  line-height: 1.75;
  border-bottom: 2px solid #F5F5F5;
}
@media screen and (max-width: 767px) {
  .law-profile__list {
    padding: 0.9375rem 1.25rem;
    display: block;
  }
}

.law-profile__term {
  max-width: 12.5rem;
  width: 100%;
  flex-shrink: 0;
}
@media screen and (max-width: 767px) {
  .law-profile__term {
    max-width: 100%;
    color: #969696;
  }
}

@media screen and (max-width: 767px) {
  .law-profile__description {
    margin-top: 0.3125rem;
  }
}

.law-profile__description a {
  color: #00AFDD;
  transition: 0.5s all ease;
}

.law-profile__description a:hover {
  opacity: 0.6;
}

.law-profile__caption {
  font-size: 12px;
  line-height: 1.75;
  margin-top: 10px;
  display: block;
}

.news {
  margin: 2.5rem 0 0;
  background: linear-gradient(180deg, transparent 0%, transparent 85%, #00AFDD 85%, #00AFDD 100%);
}

@media screen and (max-width: 767px) {
  .news__inner {
    padding: 0;
  }
}

.news__content {
  padding: 3.75rem;
  background-color: #f5f5f5;
  border-radius: 1.25rem;
}
@media screen and (max-width: 767px) {
  .news__content {
    border-radius: 1.25rem 1.25rem 0 0;
    padding: 1.25rem 0 4.375rem;
  }
}

.news__title {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 900;
}
@media screen and (max-width: 767px) {
  .news__title {
    margin-left: 1.25rem;
    font-size: 2rem;
  }
}

.news__title span {
  margin-left: 1.6875rem;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .news__title span {
    font-size: 1rem;
    margin-left: 0.625rem;
  }
}

.news__list {
  margin-top: 1.375rem;
  background-color: #fff;
  border-radius: 1.25rem;
  max-height: 21.25rem;
  overflow-x: hidden;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  .news__list {
    border-radius: 0.625rem;
    max-height: 19.5rem;
  }
}

.news-list__item {
  border-bottom: 2px solid #F5F5F5;
  position: relative;
}

.news-list__item a {
  padding: 1.25rem 2.5rem 1.25rem 0;
  display: grid;
  grid-template-columns: 9.0625rem 1fr;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .news-list__item a {
    grid-template-columns: 1fr;
    padding: 1.25rem 2.8125rem 0.9375rem 1.25rem;
  }
}

.news-list__item a:hover {
  color: #00AFDD;
}
@media screen and (max-width: 767px) {
  .news-list__item a:hover {
    color: #1E1E1E;
  }
}

.news-list__item a:after {
  content: "";
  position: absolute;
  mask-image: url(../img/common/icon_arrow_right.svg);
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: #00AFDD;
  top: 50%;
  right: 1.6875rem;
  transform: translateY(-50%);
  width: 0.625rem;
  height: 1rem;
  transition: 0.3s;
  width: 0.625rem;
}

.news-list__item div {
  padding: 1.25rem 0;
  display: grid;
  grid-template-columns: 9.0625rem 1fr;
  align-items: center;
  transition: 0.5s all ease;
}
@media screen and (max-width: 767px) {
  .news-list__item div {
    grid-template-columns: 1fr;
    padding: 1.25rem 2.8125rem 0.9375rem 1.25rem;
  }
}

.news-list__item-date {
  font-size: 1rem;
  line-height: 1;
  margin-left: 1.75rem;
  color: #969696;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .news-list__item-date {
    margin-left: 0;
  }
}

.news-list__item-title {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .news-list__item-title {
    margin-top: 0.3125rem;
    margin-left: 0;
  }
}

.news-list__item:nth-of-type(1) .news-list__item-title {
  font-weight: bold;
}

.result-sub__head {
  display: grid;
  grid-template-columns: 1fr 22.22%;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .result-sub__head {
    grid-template-columns: 1fr;
  }
}

.result-sub:nth-of-type(3) .result-sub__head {
  grid-template-columns: 1fr 31.111%;
}
@media screen and (max-width: 767px) {
  .result-sub:nth-of-type(3) .result-sub__head {
    grid-template-columns: 1fr;
  }
}

.result-sub__title {
  display: flex;
  align-items: end;
}
@media screen and (max-width: 767px) {
  .result-sub__title {
    display: block;
    text-align: center;
  }
}

.result-sub__title h3 {
  font-size: 1.75rem;
}
@media screen and (max-width: 767px) {
  .result-sub__title h3 {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

.result-sub__title span {
  font-size: 0.75rem;
  line-height: 1.75;
  margin-left: 0.5625rem;
}
@media screen and (max-width: 767px) {
  .result-sub__title span {
    margin-left: 0;
  }
}

.result-sub__head a {
  transition: 0.5s all ease;
}
@media screen and (max-width: 767px) {
  .result-sub__head a {
    max-width: 51.6417910448%;
    margin: 1.25rem auto 0;
  }
}

.result-sub__head a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 767px) {
  .result-sub:nth-of-type(3) .result-sub__head a {
    max-width: 58.8059701493%;
    margin: 1.25rem auto 0;
  }
}

.result-sub__content {
  background-color: #fff;
  border-radius: 1.25rem;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
  padding: 1.25rem 7.3125rem;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .result-sub__content {
    padding: 0.625rem 0.8125rem 1.25rem 1rem;
    border-radius: 0.625rem;
  }
}

.result-sub__content--pv {
  padding: 1.25rem 3.5rem;
}

.result-sub__container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 0.5rem;
}

.result-sub__container span {
  display: inline-block;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .result-sub__container span {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: -0.05em;
  }
}

.result-sub__number {
  font-size: 3.5rem;
  font-weight: 900;
  color: #00AFDD;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .result-sub__number {
    font-size: 3rem;
    line-height: 1.1666666667;
    letter-spacing: -0.05em;
  }
}

.result {
  padding: 5rem 0 5rem;
  overflow-x: auto;
  overflow-y: hidden;
}
@media screen and (max-width: 767px) {
  .result {
    padding: 2.5rem 0 2.5rem;
  }
}

.result__inner {
  max-width: inherit;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2.5rem;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .result__inner {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 1.25rem;
  }
}

.result__ranking {
  grid-area: 1/1/3/2;
  background-color: #E5F7FC;
  padding: 1.25rem 2.5rem 3.75rem;
  border-radius: 1.25rem;
}
@media screen and (max-width: 767px) {
  .result__ranking {
    grid-area: inherit;
    padding: 1.25rem;
    border-radius: 0.625rem;
  }
}

.result__lines {
  grid-area: 1/2/2/3;
  background-color: #E5F7FC;
  padding: 1.25rem 2.5rem 1.875rem;
  border-radius: 1.25rem;
}
@media screen and (max-width: 767px) {
  .result__lines {
    grid-area: inherit;
    padding: 1.25rem;
    border-radius: 0.625rem;
  }
}

.result__pv {
  grid-area: 2/2/3/3;
  background-color: #E5F7FC;
  padding: 1.25rem 2.5rem 1.875rem;
  border-radius: 1.25rem;
}
@media screen and (max-width: 767px) {
  .result__pv {
    grid-area: inherit;
    padding: 1.25rem;
    border-radius: 0.625rem;
  }
}

.result__ranking-head {
  display: grid;
  grid-template-columns: 1fr 22.22%;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .result__ranking-head {
    grid-template-columns: 1fr;
  }
}

.result__ranking-head h3 {
  font-size: 1.75rem;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .result__ranking-head h3 {
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
  }
}

.result__ranking-head a {
  transition: 0.5s all ease;
}
@media screen and (max-width: 767px) {
  .result__ranking-head a {
    max-width: 51.6417910448%;
    margin: 1.25rem auto 0;
  }
}

.result__ranking-head a:hover {
  opacity: 0.6;
}

.result__ranking-content {
  margin-top: 1.25rem;
}

.result__ranking-content img {
  border-radius: 1.25rem;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
}
@media screen and (max-width: 767px) {
  .result__ranking-content img {
    border-radius: 0.625rem;
  }
}

.result__ranking-notes {
  margin-top: 1.25rem;
  font-size: 0.75rem;
}

.count-size {
  font-size: 3.125rem;
}

.scroll-swiper .swiper-wrapper {
  transition-timing-function: linear !important;
}

.scroll-swiper .swiper-slide {
  width: max-content;
  flex-shrink: 0;
  height: 100%;
  transition-property: transform;
}

.scroll-swiper .slide {
  width: 24.375rem;
}
@media screen and (max-width: 767px) {
  .scroll-swiper .slide {
    width: 16.875rem;
  }
}

.scroll-swiper .slide-media {
  overflow: hidden;
  height: 16.25rem;
}
@media screen and (max-width: 767px) {
  .scroll-swiper .slide-media {
    height: 11.25rem;
  }
}

.scroll-swiper .swiper-slide img {
  object-fit: cover;
}

.scroll__inner {
  margin: auto;
  width: 100%;
}
.service-list__item {
  display: grid;
  grid-template-columns: 1fr 48.214%;
  gap: 2.5rem;
  padding: 3.75rem 0 2.5rem 3.75rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .service-list__item {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0;
  }
}

.service-list__item::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 4.375rem;
  bottom: 0;
  background-color: #fff;
  border-radius: 1.25rem;
}
@media screen and (max-width: 767px) {
  .service-list__item::before {
    right: 0;
    border-radius: 0.625rem;
  }
}

.service-list__image {
  order: 2;
  position: relative;
  aspect-ratio: 540/320;
}
@media screen and (max-width: 767px) {
  .service-list__image {
    order: 1;
  }
}

.service-list__image img {
  border-radius: 1.25rem;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
  aspect-ratio: 540/320;
}
@media screen and (max-width: 767px) {
  .service-list__image img {
    border-radius: 0.625rem;
  }
}

.service-list__image::after {
  content: "";
  position: absolute;
  background-image: url(../img/common/img_service_kashimowimax.png);
  background-size: cover;
  background-repeat: no-repeat;
  bottom: -5.4375rem;
  left: 50%;
  transform: translateX(-50%);
  width: 46.1111111111%;
  aspect-ratio: 249/220;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
}
@media screen and (max-width: 767px) {
  .service-list__image::after {
    bottom: -3.375rem;
  }
}

.service-list__content {
  order: 1;
  position: relative;
  width: 30rem;
}
@media screen and (max-width: 767px) {
  .service-list__content {
    order: 2;
    margin-top: 4.375rem;
    padding: 0 1.25rem 1.25rem;
    width: inherit;
  }
}

@media screen and (max-width: 767px) {
  .service-list__item:nth-last-of-type(2) .service-list__content {
    margin-top: 2.5rem;
  }
}

.service-list__logo {
  width: 73.3333333333%;
}
@media screen and (max-width: 767px) {
  .service-list__logo {
    margin: 0 auto;
  }
}

.service-list__content h3 {
  font-size: 1.75rem;
  line-height: 1.5;
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .service-list__content h3 {
    margin-top: 1.25rem;
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

.service-list__text {
  font-size: 1rem;
  line-height: 1.75;
}
@media screen and (max-width: 767px) {
  .service-list__text {
    margin-top: 0.625rem;
  }
}

.service-list__button {
  margin-top: 1.25rem;
}

.service-list__link {
  margin-top: 1.25rem;
  padding-left: 2.15625rem;
  position: relative;
  font-weight: bold;
  transition: 0.5s all ease;
}

.service-list__link + .service-list__link {
  margin-top: 0.75rem;
}

.service-list__link::before {
  content: "";
  position: absolute;
  mask-image: url(../img/common/icon_arrow_right.svg);
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: #00AFDD;
  top: 50%;
  left: 0.625rem;
  transform: translateY(-50%);
  width: 0.625rem;
  height: 0.9375rem;
  transition: 0.3s;
}

.service-list__link:hover a {
  color: #00AFDD;
}
@media screen and (max-width: 767px) {
  .service-list__link:hover a {
    color: #1E1E1E;
  }
}

.service-list__item + .service-list__item {
  margin-top: 2.375rem;
}
@media screen and (max-width: 767px) {
  .service-list__item + .service-list__item {
    margin-top: 1.25rem;
  }
}

.service-list li:nth-of-type(2) .service-list__image::after {
  background-image: url(../img/common/img_service_kashimohikari.png);
  bottom: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: 61.1111111111%;
  aspect-ratio: 330/50;
}

.service-list li:nth-of-type(2) .service-list__logo {
  width: 86.25%;
}

.service-list li:nth-of-type(3) {
  padding-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .service-list li:nth-of-type(3) {
    padding-bottom: 4.375rem;
  }
}

.service-list li:nth-of-type(3)::after {
  position: absolute;
  content: "※IPhone格安SIM通信は、東証プライム上場の(株)マーケットエンタープライズが運営する通信情報メディアです。";
  bottom: 2.5rem;
  left: 0;
  margin-left: 3.75rem;
  font-size: 0.75rem;
  line-height: 1.75;
}
@media screen and (max-width: 767px) {
  .service-list li:nth-of-type(3)::after {
    margin: 0 1.25rem 0;
    bottom: 1.25rem;
  }
}

.service-list li:nth-of-type(3) .service-list__logo {
  width: 82.5%;
}

.service-list li:nth-of-type(3) .service-list__image::after {
  background-image: url(../img/common/img_service_iphonekakuyasu.png);
  bottom: -2.8125rem;
  left: 50%;
  transform: translateX(-50%);
  width: 23.1481481481%;
  aspect-ratio: 125/250;
}

.service {
  background-color: #00AFDD;
  padding: 5rem 0 5rem;
}
@media screen and (max-width: 767px) {
  .service {
    border-radius: 1.25rem 1.25rem 0 0;
    margin-top: -1.25rem;
    padding: 2.5rem 0 2.5rem;
  }
}

.service__text {
  color: #fff;
  margin-top: 1.25rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .service__text {
    text-align: left;
  }
}

.service__container {
  overflow-x: auto;
  overflow-y: hidden;
}

.service__list {
  margin-top: 2.5rem;
}

.sns {
  padding: 2.5rem 0 3.75rem;
  background-color: #F5F5F5;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .sns {
    padding: 2.5rem 0 2.5rem;
  }
}

.sns h3 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: bold;
}

.sns__wrapper {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 3.75rem;
}
@media screen and (max-width: 767px) {
  .sns__wrapper {
    gap: 1.875rem;
    margin-top: 1.25rem;
  }
}

.sns__wrapper li {
  max-width: 5rem;
}
@media screen and (max-width: 767px) {
  .sns__wrapper li {
    max-width: 3.75rem;
  }
}

.sns__wrapper li a {
  transition: 0.5s all ease;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
}

.sns__wrapper li a:hover {
  opacity: 0.6;
}

.sub-company {
  margin-top: 6.75rem;
}
@media screen and (max-width: 767px) {
  .sub-company {
    margin-top: 2.5rem;
  }
}

.sub-company__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
@media screen and (max-width: 767px) {
  .sub-company__inner {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}

.sub-company__title {
  padding-left: 1.25rem;
}

.sub-company__content-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .sub-company__content-inner {
    max-width: 100%;
    margin-left: 0;
  }
}

.sub-company__profile {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .sub-company__profile {
    margin-top: 1.25rem;
  }
}

.sub-head {
  padding: 10.625rem 0 5rem;
  background-color: #00AFDD;
}
@media screen and (max-width: 767px) {
  .sub-head {
    padding: 4.375rem 0 1.0625rem;
  }
}

.sub-head__inner {
  position: relative;
}

.sub-head__title-image {
  position: absolute;
  max-width: 42.0833333333%;
  right: 5rem;
  top: -1.25rem;
}
@media screen and (max-width: 767px) {
  .sub-head__title-image {
    position: initial;
    right: initial;
    top: initial;
    max-width: 100%;
  }
}

.sub-head__title-image img {
  border-radius: 1.25rem;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
}
@media screen and (max-width: 767px) {
  .sub-head__title-image img {
    border-radius: 0.625rem;
  }
}

.sub-head .sub-head__title {
  text-align: left;
  font-size: clamp(45px, 5.3333333333vw, 64px);
}
@media screen and (max-width: 767px) {
  .sub-head .sub-head__title {
    margin-top: 0.9375rem;
  }
}

.sub-head .sub-head__title span {
  font-size: clamp(16px, 1.6666666667vw, 20px);
}

.sub-law {
  margin-top: 108px;
  margin-bottom: 80px;
}
@media screen and (max-width: 767px) {
  .sub-law {
    margin-top: 2.5rem;
  }
}

.sub-law__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
@media screen and (max-width: 767px) {
  .sub-law__inner {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}

.sub-law__title {
  padding-left: 1.25rem;
}

.sub-law__content-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .sub-law__content-inner {
    max-width: 100%;
    margin-left: 0;
  }
}

.sub-law__enactment {
  font-size: 12px;
  line-height: 1.75;
  text-align: right;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .sub-law__enactment {
    margin-top: 20px;
    margin-right: 20px;
  }
}

.sub-page-title {
  font-size: 1.75rem;
  line-height: 1.5;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .sub-page-title {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

.title {
  font-size: 4rem;
  line-height: 1;
  text-align: center;
  color: #00AFDD;
  font-family: "Monoton", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 3rem;
    text-align: left;
  }
}

.title span {
  font-family: "Noto Sans JP", sans-serif;
  margin-top: 0.625rem;
  display: block;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #1E1E1E;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .title span {
    margin-top: 0;
    font-size: 1rem;
    text-align: left;
  }
}

.title--white {
  color: #fff;
}

.title--white span {
  color: #fff;
}

.top-inner {
  padding-right: 20px;
  padding-left: 20px;
  width: 1160px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .top-inner {
    padding-right: 20px;
    padding-left: 20px;
    width: inherit;
  }
}