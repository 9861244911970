@use "global" as *;

.fv {
    padding-top: prem(110);
    background: linear-gradient(180deg, $accent 0%, $accent 60%, transparent 60%, transparent 100%);
    @include mq(md){
        background-color: $accent;
        padding-top: prem(80);
    }
}

.fv__inner {
    margin: auto;
    width: 100%; 

}